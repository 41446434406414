<!-- eslint-disable vue/valid-v-slot -->
<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <v-card height="450" class="overflow-y-scroll elevation-0" outlined>
          <v-card-title>Client data</v-card-title>
          <v-card-text>
            <p
              v-for="(clientField, k) in clientFields"
              :key="`client-resume-${k}`"
            >
              <span>{{ clientField.field }}:</span>
              <span>{{ clientField.value }}</span>
            </p>
            <p>
              <span>Documents:</span>
            </p>
            <v-row>
              <v-col cols="12">
                <v-chip-group active-class="primary--text" column>
                  <v-chip
                    v-for="(documentTag, index) in documentsTag"
                    :key="`doc-tag-${index}`"
                    small
                    >{{ documentTag }}</v-chip
                  >
                </v-chip-group>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <v-card height="300" class="overflow-y-scroll elevation-0" outlined>
          <v-card-title>Shareholders</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="shareholders"
              sort-by="shareholderId"
              class="elevation-0"
              dense
              :search="search"
              no-data-text="No shareholders available"
              :loading="false"
              loading-text="Loading shareholders..."
            >
              <template v-slot:item.name="{ item }">
                {{ shareholderName(item) }}
              </template>
              <template v-slot:item.address="{ item }">
                {{ item.address }}
              </template>
              <template v-slot:item.type="{ item }">
                {{ item.shareholderType }}
              </template>
              <template v-slot:item.documents="{ item }">
                <v-row>
                  <v-col cols="12">
                    <v-chip-group active-class="primary--text" column>
                      <v-chip
                        v-for="(document, index) in item.documents"
                        :key="`chips-documents-${index}`"
                        small
                        >{{ documentShareholder(document) }}</v-chip
                      >
                    </v-chip-group>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { documentTypes, ClientTypes } from '@src/constants/index.js'
export default {
  name: 'ResumeClient',
  props: {
    shareholders: {
      type: Array,
      default: () => [],
    },
    client: {
      type: Object,
      default: () => {},
    },
    documents: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Name',
          value: 'name',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Address',
          value: 'address',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Type',
          value: 'type',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Documents',
          value: 'documents',
          align: 'left',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    clientFields() {
      return [
        {
          field: 'Company Name',
          value: (this.client && this.client.companyName) || '',
        },
        {
          field: 'Email',
          value: (this.client && this.client.email) || '',
        },
        {
          field: 'Mobile Number',
          value: (this.client && this.client.mobileNumber) || '',
        },
        {
          field: 'Registration Address',
          value: (this.client && this.client.registrationAddress) || '',
        },
        {
          field: 'Registration Number',
          value: (this.client && this.client.registrationNumber) || '',
        },
        {
          field: 'Incorporation Country',
          value: (this.client && this.client.incorporationCountry) || '',
        },
        {
          field: 'Contact Person',
          value: (this.client && this.client.contactPerson) || '',
        },
        {
          field: 'Director',
          value: (this.client && this.client.director) || '',
        },
        {
          field: 'VAT Number',
          value: (this.client && this.client.vatNumber) || '',
        },
        {
          field: 'Incorporation Date',
          value: (this.client && this.client.incorporationDate) || '',
        },
      ]
    },
    documentsTag() {
      return this.documents.map(({ type }) => documentTypes[type])
    },
  },
  methods: {
    documentShareholder({ type }) {
      return documentTypes[type]
    },
    shareholderName({ name, shareholderType, companyName }) {
      if (shareholderType === ClientTypes.INDIVIDUAL) {
        return name
      }
      return companyName
    },
  },
}
</script>
<style>
.overflow-y-scroll {
  overflow-y: scroll;
}
</style>
