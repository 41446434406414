<!-- eslint-disable vue/valid-v-slot -->
<template>
  <SelfBuildingSpinner v-if="loading" />
  <v-row v-else>
    <v-col cols="12" class="d-flex justify-center">
      <v-btn
        v-if="!createShareholder"
        class="red darken-4"
        dark
        @click="addShareholder"
      >Add shareholder</v-btn>
    </v-col>
    <v-col cols="12">
      <v-expansion-panels accordion flat class="elevation-0">
        <v-expansion-panel
          v-for="(shareholder,i) in shareholders"
          :key="`shareholder-accordion-${i}`"
          readonly
        >
          <v-expansion-panel-header>{{ title(shareholder) }}</v-expansion-panel-header>
        </v-expansion-panel>
        <v-expansion-panel v-if="createShareholder">
          <CreateShareholder />
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>
<script>
import EventBus from '@utils/EventBus'
import api from '@src/api/index.js'
import SelfBuildingSpinner from '@components/Shared/SelfBuildingSpinner.vue'
import { EventBusEvents, ClientTypes } from '@src/constants/index.js'
import CreateShareholder from '../Shareholders/CreateShareholder.vue'

export default {
  name: 'CorporateShareHolderStep',
  components: {
    CreateShareholder,
    SelfBuildingSpinner,
  },
  props: {
    shareholders: {
      type: Array,
      default: () => [],
    },
    client: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      clientType: '',
      items: [],
      search: '',
      loading: false,
      createShareholder: false,
    }
  },
  computed: {
    clientTypes() {
      return ClientTypes
    },
  },
  beforeDestroy() {
    EventBus.$off(
      EventBusEvents.RESET_CREATE_SHAREHOLDER_ACCORDION,
      this.resetAccordion,
      this
    )
    EventBus.$off(EventBusEvents.ON_SAVE_CLICK_NEW_CLIENT, this.save, this)
  },
  mounted() {
    EventBus.$on(
      EventBusEvents.RESET_CREATE_SHAREHOLDER_ACCORDION,
      this.resetAccordion,
      this
    )
    EventBus.$on(EventBusEvents.ON_SAVE_CLICK_NEW_CLIENT, this.save, this)
  },
  methods: {
    resetAccordion() {
      this.createShareholder = false
    },
    onTypeChosen(clientType) {
      this.clientType = clientType
    },
    addShareholder() {
      this.createShareholder = true
    },
    save() {
      if (this.createShareholder) {
        EventBus.$emit(
          EventBusEvents.SNACKBAR_ERROR,
          {
            message: 'You should finish to add shareholders before saving',
          },
          this
        )
        return
      }

      if (this.shareholders.length === 0) {
        EventBus.$emit(
          EventBusEvents.SNACKBAR_ERROR,
          {
            message: 'You should add at least one shareholder',
          },
          this
        )
        return
      }
      EventBus.$emit(
        EventBusEvents.OPEN_MODAL,
        {
          confirmAction: () => this.saveClientShareholders(),
        },
        this
      )
    },
    title({ companyName, name, shareholderType }) {
      if (shareholderType === this.clientTypes.INDIVIDUAL) {
        return name
      }
      return companyName
    },
    async saveClientShareholders() {
      this.loading = true
      const shareholdersId = this.shareholders.map(
        ({ shareholderId }) => shareholderId
      )
      try {
        await api.createClientShareholders({
          client: this.client,
          shareholdersId,
        })
        this.$router.push({ name: 'clients' })
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
  },
}
</script>
<style>
.v-expansion-panel:before {
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>