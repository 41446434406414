<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-form ref="corporateClientForm">
    <SelfBuildingSpinner v-if="loading" />
    <div v-else>
      <v-row class="px-10">
        <v-col cols="4">
          <v-radio-group v-model="status" mandatory row>
            <v-radio
              color="red darken-4"
              label="Active"
              value="active"
            ></v-radio>
            <v-radio
              color="red darken-4"
              label="Abandoned"
              value="abandoned"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="2">
          <v-checkbox v-model="accounting" label="Enable Accounting"/>
        </v-col>
      </v-row>
      <v-row class="px-10">
        <v-col cols="4">
          <v-text-field
            v-model="companyName"
            color="red darken-4"
            outlined
            dense
            mandatory
            :error-messages="requiredError($v.companyName, 'Company Name')"
            label="Company Name"
            @change="$v.companyName.$touch"
            @blur="$v.companyName.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="email"
            color="red darken-4"
            outlined
            dense
            :error-messages="emailErrors"
            label="Email"
            @change="$v.email.$touch"
            @blur="$v.email.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="mobileNumber"
            color="red darken-4"
            outlined
            dense
            :error-messages="phoneErrors"
            label="Mobile Number"
            @change="$v.mobileNumber.$touch"
            @blur="$v.mobileNumber.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-combobox
            v-model="registrationAddress"
            :append-icon="null"
            :items="addresses"
            :search-input.sync="search"
            color="red darken-4"
            dense
            hide-no-data
            outlined
            item-text="companyName"
            item-value="id"
            :error-messages="
              requiredError($v.registrationAddress, 'Registration address')
            "
            label="Registration Address"
            @change="$v.registrationAddress.$touch"
            @blur="$v.registrationAddress.$touch"
          />
          <v-text-field
            v-if="false"
            v-model="registrationAddress"
            color="red darken-4"
            outlined
            dense
            :error-messages="
              requiredError($v.registrationAddress, 'Registration address')
            "
            label="Registration Address"
            @change="$v.registrationAddress.$touch"
            @blur="$v.registrationAddress.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="registrationNumber"
            color="red darken-4"
            outlined
            dense
            label="Registration Number"
            :error-messages="
              requiredError($v.registrationNumber, 'Registration Number')
            "
            @change="$v.registrationNumber.$touch"
            @blur="$v.registrationNumber.$touch"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="vatNumber"
            color="red darken-4"
            outlined
            dense
            label="VAT Number"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="contactPerson"
            color="red darken-4"
            outlined
            dense
            label="Contact Person"
            :error-messages="requiredError($v.contactPerson, 'Contact Person')"
            @change="$v.contactPerson.$touch"
            @blur="$v.contactPerson.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="director"
            color="red darken-4"
            outlined
            dense
            label="Director"
            :error-messages="requiredError($v.director, 'Director')"
            @change="$v.director.$touch"
            @blur="$v.director.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-menu
            ref="menuIncorporationDate"
            v-model="incorporationDateMenu"
            :close-on-content-click="false"
            :return-value.sync="incorporationDateMenu"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="incorporationDate"
                color="red darken-4"
                outlined
                dense
                label="Incorporation Date"
                prepend-icon="mdi-calendar"
                readonly
                :error-messages="
                  requiredError($v.incorporationDate, 'Incorporation date')
                "
                v-on="on"
                @change="$v.incorporationDate.$touch"
                @blur="$v.incorporationDate.$touch"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="incorporationDate"
              no-title
              scrollable
              color="red darken-4"
            >
              <div class="flex-grow-1"></div>
              <v-btn
                text
                color="red darken-4"
                @click="incorporationDateMenu = false"
                >Cancel</v-btn
              >
              <v-btn
                text
                color="red darken-4"
                @click="$refs.menuIncorporationDate.save(incorporationDate)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="incorporationCountry"
            color="red darken-4"
            outlined
            dense
            mandatory
            :items="countryList"
            :error-messages="
              requiredError($v.incorporationCountry, 'Incorporation country')
            "
            prepend-icon="mdi-map"
            label="Country of Incorporation"
            @change="$v.incorporationCountry.$touch"
            @blur="$v.incorporationCountry.$touch"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col cols="3">
              <p>Organigram: </p>
            </v-col>
              <v-col cols="9">
                <div class="col-4-drag-drop">
                  <img :src="image" alt="" class="image-class" cols="12" />
                  <vue-dropzone id="drop1"
                    ref="dropzone"
                    class="drag-drop-image"
                    :options="dropOptions"
                    :include-styling="false"
                    :use-custom-slot="true"
                    @vdropzone-complete="afterCompleteForOrganigram"
                    ></vue-dropzone>
                  </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p>Documents</p>
          <p>No compulsory document required, all of them are optionals</p>
        </v-col>
        <v-col v-if="documents.length !== 0" cols="12">
          <div
            v-for="(document, index) in documents"
            :key="`shareholder-document-${index}`"
          >
            <v-row justify="center">
              <v-spacer></v-spacer>
              <v-col cols="4">
                <v-select
                  v-model="document.type"
                  item-text="label"
                  item-value="type"
                  color="red darken-4"
                  outlined
                  dense
                  mandatory
                  :items="documentList"
                  label="Document Type"
                  :error-messages="
                    requiredError(
                      $v.documents.$each[index].type,
                      'Document Type',
                    )
                  "
                  @change="$v.documents.$each[index].type.$touch"
                  @blur="$v.documents.$each[index].type.$touch"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item
                      :disabled="item.selected"
                      :attrs="attrs"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ item.label }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="4">
                 <div class="col-4-drag-drop">
                <img :src="image" alt="" class="image-class" cols="12" />
                 <vue-dropzone id="drop1"
                  ref="dropzone"
                  class="drag-drop-image"
                  :options="dropOptions"
                  :include-styling="false"
                  :use-custom-slot="true"
                  @vdropzone-complete="afterComplete"
                  @vdropzone-removed-file="removeThisFile"
                  ></vue-dropzone>
                </div>
              </v-col>
              <v-col cols="1">
                <v-btn
                  icon
                  color="red darken-4"
                  dark
                  @click="deleteDocument(index)"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col v-if="document.type === 'other'" cols="12">
                <v-row no-gutters>
                  <v-spacer></v-spacer>
                  <v-col cols="8">
                    <v-textarea
                      v-model="document.comment"
                      outlined
                      dense
                      color="red darken-4"
                      auto-grow
                      label="Comment"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="1"></v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" class="d-flex align-center justify-center">
          <v-btn
            v-if="!documentsFull"
            color="teal lighten-1"
            dark
            @click="addDocument"
            ><v-icon class="mr-2">mdi-file-document</v-icon>Add a
            document</v-btn
          >
          <v-btn color="teal lighten-1" class="ml-2" dark @click="saveClient"
            >Save client details</v-btn
          >
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>
<script>
import { getNames } from 'country-list'
import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'
import {
  mobilePhoneValidator,
  validExtension,
  validateError,
} from '@utils/validateFields'
import EventBus from '@utils/EventBus'
import SelfBuildingSpinner from '@components/Shared/SelfBuildingSpinner.vue'
import { EventBusEvents, ClientTypes } from '@src/constants/index.js'
import api from '@src/api/index.js'
import isAfter from 'date-fns/isAfter'
import isToday from 'date-fns/isToday'
import vueDropzone from 'vue2-dropzone'
const imageURL = require('../../assets/cloud.png')

const getTemplate = () => `
<div class="dz-preview dz-file-preview">
  <div class="dz-image">
    <div data-dz-thumbnail-bg></div>
  </div>
  <div class="dz-details">
    <div class="dz-size"><span data-dz-size></span></div>
    <div class="dz-filename"><span data-dz-name></span></div>
  </div>
  <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
  <div class="dz-error-message"><span data-dz-errormessage></span></div>
  <div class="dz-success-mark"><i class="fa fa-check"></i></div>
  <div class="dz-error-mark"><i class="fa fa-close"></i></div>
</div>
`;

export default {
  name: 'CorporateClientStep',
  components: {
    SelfBuildingSpinner,
     vueDropzone,
  },
  mixins: [validationMixin],
  props: {
    shareholders: {
      type: Array,
      default: () => [],
    },
  },
  validations() {
    return {
      registrationAddress: { required },
      incorporationDate: { required },
      companyName: { required },
      email: {
        required,
        email,
      },
      mobileNumber: {
        required,
        mobilePhoneValidator,
      },
      contactPerson: { required },
      director: { required },
      incorporationCountry: { required },
      registrationNumber: { required },
      documents: {
        $each: {
          type: { required },
          file: { validExtension },
        },
      },
    }
  },
  data() {
    return {
      addresses: ['1, Floor 2, Falzun Street, c/w Naxxar Road, Birkirkara'],
      address: '',
      incorporationCountry: '',
      incorporationDate: '',
      companyName: '',
      registrationNumber: '',
      email: '',
      autocompleteValue: null,
      incorporationDateMenu: '',
      mobileNumber: '',
      loading: false,
      accounting: false,
      director: '',
      contactPerson: '',
      vatNumber: '',
      status: null,
      name: '',
      search: '',
      selectedShareHolder: {},
      nationality: '',
      registrationAddress: '',
      organigram: '',
      documents: [],
      corporateDocuments: [
        {
          label: 'Vat certificate',
          type: 'vatCertificate',
        },
        {
          label: 'Incorporation Certificate',
          type: 'incorporationCertificate',
        },
        {
          label: 'M&A',
          type: 'm&a',
        },
        {
          label: 'Letter of engagement',
          type: 'engagementLetter',
        },
        {
          label: 'SIA Director',
          type: 'siaDirector',
        },
        {
          label: 'SIA Company Secretary',
          type: 'siaCompanySecretary',
        },
        {
          label: 'Authorization with MBR',
          type: 'authorizationMbr',
        },
        {
          label: 'Other',
          type: 'other',
        },
      ],
      dropOptions: {
        url: 'https://httpbin.org/post',
        maxFilesize: 2, // MB
        maxFiles: 20,
        chunking: false,
        chunkSize: 500, // Bytes
        thumbnailWidth: 75, // px
        thumbnailHeight: 75,
        addRemoveLinks: true,
        previewTemplate: getTemplate()
      },
      image: imageURL,
    }
  },
  computed: {
    countryList() {
      return getNames()
    },
    documentsFull() {
      return this.documents.length === this.corporateDocuments.length
    },
    emailErrors() {
      return validateError(
        this.$v.email,
        ['required', 'email'],
        ['Email is required', 'Email is invalid']
      )
    },
    phoneErrors() {
      return validateError(
        this.$v.mobileNumber,
        ['required', 'mobilePhoneValidator'],
        ['Mobile number is required', 'Mobile number is invalid']
      )
    },
    documentList() {
      const documentsInserted = this.documents.map(({ type }) => type)

      return this.corporateDocuments
        .map(({ label, type }) => ({
          label,
          type,
          selected: documentsInserted.includes(type),
        }))
        .sort((a, b) => Number(a.selected) - Number(b.selected))
    },
  },
  methods: {
    addDocument() {
      this.documents.push({
        type: '',
        label: '',
        menu: '',
        number: '',
        comment: '',
        expiryDate: '',
        file: null,
      })
    },
    fileErrors(file) {
      return validateError(
        file,
        ['validExtension'],
        ['Extension wrong, accepted: png, jpg, pdf']
      )
    },
    allowedDates(val) {
      return (
        isToday(new Date(val), new Date()) || isAfter(new Date(val), new Date())
      )
    },
    requiredError(field, fieldName) {
      return validateError(field, ['required'], `${fieldName} is required`)
    },
    deleteDocument(index) {
      this.documents.splice(index, 1)
    },
    saveClient() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        const err = {
          message: 'Field validation failed. Check all fields inserted.',
        }
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
        return
      }
      const clientInfoPayload = {
        companyName: this.companyName,
        nationality: this.nationality,
        email: this.email,
        contactPerson: this.contactPerson,
        registrationAddress: this.registrationAddress,
        registrationNumber: this.registrationNumber,
        clientType: ClientTypes.CORPORATE,
        director: this.director,
        status: this.status,
        mobileNumber: this.mobileNumber,
        incorporationDate: this.incorporationDate,
        incorporationCountry: this.incorporationCountry,
        vatNumber: this.vatNumber,
        accounting: this.accounting
      }
      EventBus.$emit(
        EventBusEvents.OPEN_MODAL,
        {
          confirmAction: () => this.saveCorporateClientInfo(clientInfoPayload),
        },
        this
      )
    },
    async saveCorporateClientInfo(payload) {
      this.loading = true
      try {
        const client = await api.createClient(payload)
        this.loading = false
        return this.saveCorporateClientDocuments(client)
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    async saveCorporateClientDocuments(client) {
      this.loading = true
      try {
        const documents = await api.createClientDocuments({
          client,
          documents: this.documents,
          organigramData: this.organigram
        })
        this.loading = false
        this.$emit('onNewClientCreated', { client, documents })
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.OPEN_MODAL, {
          title: 'An error occurred',
          message: err,
          activeCancel: false,
          confirmAction: () => this.$router.push({ name: 'clients' }),
        })
      }
    },
      afterCompleteForOrganigram(file) {
      this.file = file;
      this.organigram = file;
    },
     afterComplete(file) {
      this.documents.forEach(element => {
        if(element.file === null) {
            element.file = file;
        }
      });
    },
    removeThisFile(file) {
      this.documents.forEach(element => {
        if(element.file.name === file.name) {
            element.file = null;
        }
      });
    },
  },
}
</script>
<style>
.col-4-drag-drop {
    border: darkgrey 2px dotted;
    height: auto;
    border-radius: 5px;
    padding: 16px;
}
.drag-drop-image {
    margin-left: 100px;
    margin-top: -30px;
}
.image-class {
    height: 30px;
    margin-left: 60px;
    margin-top: 1px;
}
</style>
