<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-col cols="2">
        <v-btn outlined color="indigo" x-large @click="$emit('onTypeChosen', shareholderTypes.INDIVIDUAL)">
          <v-icon>mdi-account</v-icon>Individual
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn outlined color="teal" x-large @click="$emit('onTypeChosen', shareholderTypes.CORPORATE)">
          <v-icon>mdi-office-building</v-icon>Corporate
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { ClientTypes } from '@src/constants/index.js'
export default {
  name: 'ChooseShareholderTypeStep',
  computed: {
    shareholderTypes() {
      return ClientTypes
    },
  },
}
</script>
<style >
</style>