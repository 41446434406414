<template>
  <div>
    <transition-group name="fade">
      <component
        :is="componentToShow"
        :key="componentKey"
        :is-into-client-step="true"
        @onTypeChosen="onTypeChosen"
      />
    </transition-group>
  </div>
</template>
<script>
import ChooseShareholderTypeStep from '../Shared/ChooseShareholderTypeStep.vue'
import ShareholderIndividualStep from '../Shared/ShareholderIndividualStep.vue'
import ShareholderCorporateStep from '../Shared/ShareholderCorporateStep.vue'
import { ClientTypes } from '@src/constants/index.js'

export default {
  name: 'CreateShareholder',
  components: {
    ChooseShareholderTypeStep,
    ShareholderIndividualStep,
    ShareholderCorporateStep,
  },
  data() {
    return {
      shareholderType: '',
      shareholderSteps: {
        CHOOSE_TYPE: 'chooseType',
        INDIVIDUAL: 'individual',
        CORPORATE: 'corporate',
      },
      shareholderStep: 'chooseType',
    }
  },
  computed: {
    shareholderTypes() {
      return ClientTypes
    },
    componentToShow() {
      if (this.shareholderStep === this.shareholderSteps.CHOOSE_TYPE) {
        return 'ChooseShareholderTypeStep'
      }
      if (this.shareholderStep === this.shareholderSteps.INDIVIDUAL) {
        return 'ShareholderIndividualStep'
      }
      return 'ShareholderCorporateStep'
    },
    componentKey() {
      return this.shareholderStep === this.shareholderSteps.CHOOSE_TYPE
        ? 'chooseShareholder'
        : 'detailShareholder'
    },
  },
  methods: {
    onTypeChosen(shareholderType) {
      this.shareholderType = shareholderType
      this.shareholderStep =
        shareholderType === this.shareholderTypes.INDIVIDUAL
          ? this.shareholderSteps.INDIVIDUAL
          : this.shareholderSteps.CORPORATE
    },
  },
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>